import { useRef, useEffect, useContext } from 'react'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import { useIsMounted } from '../../../../utilities/hooks'
import { Context } from '../../../../contexts/GlobalContext'

const NotificationManager = () => {
  const {sandboxSetState, loadChats} = useContext(SandBoxContext)
  const {syncUser} = useContext(Context)
  const socketRef = useRef(null)
  const isConnectedRef = useRef(false)
  const isMounted = useIsMounted()

  const notificationEventHandler = (event) => {
    let notification = {}
    try {
      notification = JSON.parse(event.data)
    } catch(err) {
      notification = {}
    }
    let {type = '', message = '', metadata = {}} = notification
    if(type === 'new-assistant-msg') {
      loadChats()
      // refreshes the schedules list
      syncUser()
    }
  }

  const connectWebSocket = (willRetryOnClose=true) => {
    if (socketRef.current || isConnectedRef.current) {
      return
    }

    const wsURL = process.env.NODE_ENV === 'development'
      ? 'ws://localhost:9001/socket/'
      : 'wss://sellagen.com/socket/'

    const ws = new WebSocket(wsURL)

    ws.onopen = () => {
      isConnectedRef.current = true
      sandboxSetState({isOnline: true})
    }

    ws.onmessage = (event) => {
      notificationEventHandler(event)
    }

    ws.onclose = (event) => {
      isConnectedRef.current = false
      socketRef.current = null
      sandboxSetState({isOnline: false})
      if(willRetryOnClose && isMounted) {
        connectWebSocket(false)
      }
    }

    ws.onerror = (error) => {
      // console.error('WebSocket error:', error)
      isConnectedRef.current = false
      socketRef.current = null
    }
    socketRef.current = ws
  }

  useEffect(() => {
    connectWebSocket()

    const intervalId = setInterval(() => {
      if (!isConnectedRef.current) {
        connectWebSocket()
      }
    }, 15000)

    return () => {
      clearInterval(intervalId)
      if (socketRef.current) {
        socketRef.current.close()
      }
    }
  }, [])

  return null
}

export default NotificationManager
