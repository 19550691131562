import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles.scss'
import { copyToClipboard, useAutosizeTextArea } from '../../utilities/handyFunctions'
import Spinner from '../../components/spinner'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { json } from '@codemirror/lang-json'
import { atomone } from '@uiw/codemirror-theme-atomone'
import {ConsoleContext} from '../../contexts/ConsoleContext'
import Axios from '../../utilities/axios'
import { useInterval, useIsMounted, useStatePro } from '../../utilities/hooks'
import SandBoxContext from '../../contexts/SandBoxContext'
import ToolTip from '../../components/tool-tip'
import Agent from './title'
import { Context } from '../../contexts/GlobalContext'
import { FlowButton, FlowInput } from '../../components/flow-ui/flow-blocks'
import { InfoToolTip } from './sandbox/panel-utils'
import { CreateEnvVarOverlay } from './sandbox/overlayPanels'

const AIPanel = ({
  onSubmit = () => {},
  isLoading,
  onChange,
  value,
  placeHolder='',
  proMode=false,
  onLLMSelection=() => {},
  onActionSelection=() => {},
  testMode=false,
  onTestToggle=() => {}
}) => {
  const textAreaRef = useRef(null)
  const [isPromptFocused, setIsPromptFocused] = useState(false)
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  useAutosizeTextArea(textAreaRef.current, value)
  var panelMode = 'action'
  if(sandboxState?.panelMode) panelMode = sandboxState?.panelMode
  return (
    <div className='sv2-spdrn-ai-panel-container'>
      {
        proMode ? 
        <div style={{border: '2px solid rgb(75, 80, 90)', borderRadius: '10px', padding: '10px', backgroundColor: 'rgb(35, 38, 45)'}}>
          <div className='sv2-spdrn-ai-input-pro-ctrlbox'>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Ask <Agent/> to do something.</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'action'}) }}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'action' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-project-diagram pro-icon"></i></span>Action
              </div>
            </ToolTip>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Integrate your own Python function into <Agent/>.</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'integrate', showSidePanel: true}) }}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'integrate' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-drafting-compass pro-icon"></i></span>Integrate
              </div>
            </ToolTip>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Configure the context <Agent/> knows about you.</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'context', showSidePanel: true}) }}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'context' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-book pro-icon"></i></span>Context
              </div>
            </ToolTip>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Add web services to Nelima</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'services', showSidePanel: true})}}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'services' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-sparkles pro-icon"></i></span>Services
              </div>
            </ToolTip>
            {/* <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Ask Nelima to do something later (Coming Soon)</TipCard>}
            >
              <div className='sv2-spdrn-ai-input-pro-btn pro-disabled'>
                <span style={{fontSize: '16px'}}><i className="fal fa-clock pro-icon"></i></span>Schedule
              </div>
            </ToolTip> */}
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Give Nelima file access<br/>(Coming Soon)</TipCard>}
            >
              <div className='sv2-spdrn-ai-input-pro-btn pro-disabled'>
                <span style={{fontSize: '16px'}}><i className="fal fa-folders pro-icon"></i></span>Files
              </div>
            </ToolTip>
          </div>
          <form 
            className='sv2-spdrn-ai-input-form'
            style={{backgroundColor: 'rgb(50, 53, 60)', borderRadius: '5px'}}
            action='#'
          >
            <div className='sv2-spdrn-ai-icon'>
              {
                isLoading ?
                <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
                :
                <i className={`${isPromptFocused ? 'fas fa-bolt glow': 'far fa-bolt'}`} aria-hidden={true}></i>
              }
            </div>
            <textarea
              id='sv2-spdrn-ai-id'
              className='sv2-spdrn-ai-input-div pro-form'
              rows={1}
              placeholder={placeHolder}
              ref={textAreaRef}
              readOnly={isLoading}
              onChange={(e) => { if(onChange) onChange(e) }}
              data-gramm={false} // eliminate grammarly
              data-gramm_editor={false}
              data-enable-grammarly={false}
              onKeyDown={e => { 
                if(e.key === 'Enter' && !e.shiftKey) {
                  onSubmit()
                  e.preventDefault()
                }
              }}
              onFocus={() => { setIsPromptFocused(true) }}
              onBlur={() => { setIsPromptFocused(false) }}
              value={value || ''}
            />
            {
              isLoading ?
              <div className="sv2-spdrn-ai-input-submit" onClick={() => {
                try {
                  sandboxState.axiosController.abort()
                } catch(err) {
                  console.log('Can not cancel')
                }
              }}>
                <i className="fas fa-stop-circle"></i>
              </div>
              :
              <div className="sv2-spdrn-ai-input-submit" onClick={onSubmit}>
                <i className="fas fa-arrow-circle-right"></i>
              </div>  
            }
          </form>
          <div style={{width: '100%', fontSize: '12px', fontWeight: 500, color: 'rgb(150, 150, 150)', paddingTop: '8px', marginBottom: '-3px'}}>
            <div className='sv2-spdrn-pro-option-base'>LLM</div>
            <div className='sv2-spdrn-pro-option-base option-spacing option-btn option-selected'
              onClick={() => {onLLMSelection('chat-gpt')}}
            >Multi-Agent</div>
            {/* <div className='sv2-spdrn-pro-option-base option-disabled'
              onClick={() => {onLLMSelection('llama-2')}}
            >Llama-2</div> */}
            <span style={{fontSize: '16px', cursor: 'default'}}>|</span>
            <div className='sv2-spdrn-pro-option-base'>Actions</div>
            {
              testMode ?
              <>
                <div className='sv2-spdrn-pro-option-base option-spacing option-btn'
                  onClick={() => onActionSelection('experimental')}
                >Experimental</div>
                <div className='sv2-spdrn-pro-option-base option-spacing option-disabled'
                  // onClick={() => onActionSelection('general')}
                >General</div>
                <div className='sv2-spdrn-pro-option-base option-btn option-selected'
                  style={{color: 'orange'}}
                  onClick={onTestToggle}
                >Test Mode&nbsp;<i className="far fa-stop-circle"></i></div>
              </>
              :
              <>
                <div className='sv2-spdrn-pro-option-base option-spacing option-btn option-selected'
                  onClick={() => onActionSelection('experimental')}
                >Experimental</div>
                <div className='sv2-spdrn-pro-option-base option-spacing option-disabled'
                  // onClick={() => onActionSelection('general')}
                >General</div>
                {
                  sandboxState?.erroredPrompt === true ?
                  <div className='sv2-spdrn-pro-option-base'
                    style={{float: 'right', color: 'crimson'}}
                    // onClick={() => onActionSelection('general')}
                  ><strong>Try again later</strong></div>
                  :
                  null
                }
              </>
            }
          </div>
        </div>
        :
        <form 
          className='sv2-spdrn-ai-input-form'
          action='#'
        >
          {/* <AIPrompt/> */}
          <div className='sv2-spdrn-ai-icon'>
            {
              isLoading ?
              <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
              :
              <i className={`${isPromptFocused ? 'fas fa-bolt glow': 'far fa-bolt'}`} aria-hidden={true}></i>
            }
          </div>
          <textarea
            id='sv2-spdrn-ai-id'
            className='sv2-spdrn-ai-input-div'
            rows={1}
            placeholder='Ask AI to write code or run something'
            ref={textAreaRef}
            readOnly={isLoading}
            onChange={(e) => { if(onChange) onChange(e) }}
            data-gramm={false} // eliminate grammarly
            data-gramm_editor={false}
            data-enable-grammarly={false}
            onKeyDown={e => { 
              if(e.key === 'Enter' && !e.shiftKey) {
                onSubmit()
                e.preventDefault()
              }
            }}
            onFocus={() => { setIsPromptFocused(true) }}
            onBlur={() => { setIsPromptFocused(false) }}
            value={value || ''}
          />
        </form>
      }
    </div>
  )
}

const TipCard = ({children}) => (
  <div style={{
    background: 'rgb(35, 38, 45)',
    border: '2px solid rgb(72, 74, 78)',
    color: 'rgb(215, 215, 215)',
    borderRadius: '4px',
    padding: '3px 6px',
    fontSize: '10px',
    textAlign: 'center'
  }}>
    {children}
  </div>
)

const CodePanel = () => {
  const { editorRef, state, registerScriptUpdate } = useContext(ConsoleContext)
  useEffect(() => {
    setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 500)
    setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 3000)
  }, [])
  // console.log(state.activeComponent, state.activeEditor)
  return(
    <CodeMirror
      ref={editorRef}
      value={
        state.activeEditor === 'compute' ? (state.mainContent || '')
          :
          state.activeEditor === 'deploy' ? (state.deployContent || '# The Deployment Function is triggered when an API endpoint is called.\n# Write your code logic inside the process function.\n\ndef process(function_input):\n  ## function logic goes here\n  ## The returned output will be included in the response body.\n  return None') : ''
      }
      onChange={registerScriptUpdate}
      className='sv2-codemirror-configure'
      height='100% !important'
      editable={true}
      autoFocus={true}
      theme={atomone}
      extensions={[python()]}
      indentWithTab={true}
      basicSetup={{
        lineNumbers: true,
        highlightActiveLineGutter: true,
        highlightSpecialChars: true,
        history: true,
        foldGutter: true,
        drawSelection: true,
        dropCursor: true,
        allowMultipleSelections: true,
        indentOnInput: true,
        syntaxHighlighting: true,
        bracketMatching: true,
        closeBrackets: true,
        autocompletion: true,
        rectangularSelection: true,
        crosshairCursor: true,
        highlightActiveLine: true,
        highlightSelectionMatches: true,
        closeBracketsKeymap: true,
        defaultKeymap: true,
        searchKeymap: true,
        historyKeymap: true,
        foldKeymap: true,
        completionKeymap: true,
        lintKeymap: true
      }}
    />
  )
}

const LogViewer = () => {
  const { state, setState, downloadOutput } = useContext(ConsoleContext)
  const [result, setResult] = useState({})
  const [isLoadingViewer, setIsLoadingViewer] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [isLoadingLog, setIsLoadingLog] = useState(false)
  const [logContent, setLogContent] = useState('')
  const isMounted = useIsMounted()
  
  const logRef = useRef(null)
  const [activeLog, setActiveLog] = useStatePro('')
  
  useEffect(() => {
    syncLogs()
  }, [])

  useEffect(() => {
    syncActiveLog()
  }, [activeLog])

  useInterval(() => syncActiveLog(), 10000)

  useEffect(() => {
    if(logContent) {
      if(logRef?.current?.scrollTo) {
        logRef?.current?.scrollTo(0, logRef?.current?.scrollHeight)
      }
    }
  }, [logContent])

  const syncLogs = () => {
    if(state.project?._id && !isLoadingViewer) {
      setIsLoadingViewer(true)
      var projectID = state.project?._id
      Axios.post(`/project/details`, {projectID}).then(async response => {
        if(isMounted) {
          if(response.status === 200 && response.data) {
            const project = response.data
            if(project?.versionDetails?.allVersions?.length > 0) {
              var showVersion = project.versionDetails.allVersions.filter(v => v.version === state?.workingOnVersion)
              if(showVersion.length > 0) {
                setResult(showVersion[0])
                // todo: default log selection - localStorage
                if(showVersion[0]?.output?.length > 0) {
                  setActiveLog(showVersion[0].output[0])
                } else {
                  setActiveLog(null)
                }
                setErrorMsg(null)
              } else {
                setErrorMsg('Error Loading Logs')
              }
            } else {
              setErrorMsg('Error Loading Logs')
            }
          } else {
            setErrorMsg('Error Loading Logs')
          }
        }
        setIsLoadingViewer(false)
      }).catch(err => {
        if(isMounted) {
          setErrorMsg('Error Loading Logs')
          setIsLoadingViewer(false)
        }
      })
    }
  }

  const syncActiveLog = () => {
    if(activeLog && !isLoadingLog) {
      var outputType = null
      if(activeLog === 'compute-output.log') {
        outputType = 'compute-output'
      } else if(activeLog === 'deploy-output.log') {
        outputType = 'deploy-output'
      } else {
        return
      }
      setIsLoadingLog(true)
      downloadOutput(outputType).then(content => {
        if(isMounted) {
          setIsLoadingLog(false)
          setLogContent(content)
          setErrorMsg(null)
        }
      }).catch(err => {
        if(isMounted) {
          setIsLoadingLog(false)
          setErrorMsg('Error Loading Log')
        }
      })
    }
  }

  // if(state.logMinimized) return null

  if(isLoadingViewer) {
    return (
      <div className='sv2-spdrn-logs-container'>
        <div className='sv2-spdrn-logs' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          Fetching Logs&nbsp;&nbsp;<Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
        </div>
      </div>
    )
  }

  var logTabs = []
  if(result?.output?.length > 0) {
    logTabs = result.output.map((l, i) => {
      if(l === 'compute-output.log') {
        return (
          <span key={i}>main.py</span>
        )
      } else {
        return (
          <span key={i}>function.py</span>
        )
      }
    })
  }

  return (
    <div className='sv2-spdrn-logs-container'>
      <div className='sv2-spdrn-logs'>
        <div className='sv2-spdrn-log-header'>
          Logs&nbsp;•&nbsp;{logTabs.length > 0 ? logTabs : 'None'}&nbsp;&nbsp;{isLoadingLog && <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>}
          &nbsp;
          <div style={{
            position: 'absolute', top: '0px', right: '15px', height: '30px',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}>
            <span style={{color: 'crimson', opacity: 1}}>{errorMsg}</span>&nbsp;&nbsp;
            <div className='sv2-spdrn-save-btn'
              onClick={() => { setState({logMinimized: true}) }}
            >
              <i className="far fa-window-minimize"></i>
            </div>
          </div>
        </div>
        { logContent ?
          <div className='sv2-spdrn-log-content' ref={logRef}>
            {logContent}
          </div>
          :
          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            No Log
          </div>
        }
      </div>
    </div>
  )
}

const SimpleCodePanel = ({}) => {
  const { editorRef, sandboxState, sandboxSetState } = useContext(SandBoxContext)
  const scriptRef = useRef(sandboxState.script)

  useEffect(() => {
    var timoutID0 = setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 500)
    var timoutID1 = setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 3000)

    return () => {
      clearTimeout(timoutID0)
      clearTimeout(timoutID1)
      sandboxSetState({script: scriptRef.current})
    }
  }, [])

  var editable = true
  if(sandboxState?.metadata?.editorActive === false) editable = false
  return(
    <div style={{width: '100%', height: 'calc(100% - 145px)', display: 'flex'}}>
      <div className='simple-editor'>
        <CodeMirror
          ref={editorRef}
          value={sandboxState.script || ''}
          onChange={(e) => {
            scriptRef.current = e
          }}
          className='sv2-codemirror-configure'
          height='100% !important'
          editable={editable}
          autoFocus={true}
          theme={atomone}
          extensions={[python()]}
          indentWithTab={true}
          basicSetup={{
            lineNumbers: true,
            highlightActiveLineGutter: true,
            highlightSpecialChars: true,
            history: true,
            foldGutter: true,
            drawSelection: true,
            dropCursor: true,
            allowMultipleSelections: true,
            indentOnInput: true,
            syntaxHighlighting: true,
            bracketMatching: true,
            closeBrackets: true,
            autocompletion: true,
            rectangularSelection: true,
            crosshairCursor: true,
            highlightActiveLine: true,
            highlightSelectionMatches: true,
            closeBracketsKeymap: true,
            defaultKeymap: true,
            searchKeymap: true,
            historyKeymap: true,
            foldKeymap: true,
            completionKeymap: true,
            lintKeymap: true
          }}
        />
      </div>
    </div>
  )
}

const AuthStorageManager = ({}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {sandboxState, sandboxSetState, setOverlay, closeWindow} = useContext(SandBoxContext)
  let authStorage = []

  const initialLoadingHandler = async () => {
    let authStorageCache = []
    try {
      if(sandboxState?.authStorageCache === null) {
        setIsLoading(true)
        let response = await Axios.post('authStorage/read', {authSecretIDs: []})
        if(response.data?.authSecrets?.length >= 0) {
          authStorageCache = response.data?.authSecrets || []
        }
        sandboxSetState({authStorageCache})
        setIsLoading(false)
      }
    } catch(err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initialLoadingHandler()
  }, [])

  let _authStorage = sandboxState?.authStorageCache ?? []
  if(searchTerm) {
    _authStorage = authStorage.filter(
      a => {
        let n = String(a?.name).toLowerCase()
        let k = String(a?.key).toLowerCase()
        let s = searchTerm.trim().toLowerCase()
        return n.includes(s) || k.includes(s)
      }
    )
  }
  let storageView = null
  if(_authStorage?.length > 0) {
    storageView = (<div>{_authStorage.map(a => <AuthStorageCard key={a._id} authSecret={a}/>)}</div>)
  } else {
    storageView = (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', opacity: 0.7}}>
        <span style={{fontWeight: 500, fontSize: '18px'}}>No Environment Variables</span>
        <br/>
        <span style={{fontStyle: 'italic', fontSize: '11px'}}>
          {
            authStorage?.length === 0
            ?
            'No environment variables have been stored yet'
            :
            'No environment variables found with given search term'
          }
        </span>
        <br/>
        <FlowButton style={{lineHeight: 1.0, fontSize: '14px'}}
          onClick={() => {
            setOverlay(<CreateEnvVarOverlay/> , false, 'left')
          }}
        ><i className="fal fa-plus-circle"></i>&nbsp;Add Environment Variable</FlowButton>
      </div>
    )
  }
  return(
    <div style={{width: '100%', height: 'calc(100% - 145px)', display: 'flex'}}>
      <div className='auth-storage-manager-container'>
        <div className='auth-storage-manager'>
          <div style={{width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.05)', padding: '15px', position: 'relative'}}>
            <div style={{fontWeight: 500,fontSize: '20px', display: 'flex', alignItems: 'center', width: '100%'}}>
              <i style={{
                backgroundColor: 'rgb(255, 165, 0, 0.15)', color: 'rgb(255, 165, 0)',
                padding: '6px', borderRadius: '5px', marginRight: '10px', fontSize: '16px'
              }} className="fas fa-key"></i>Environment Variables
              <span style={{
                cursor: 'pointer', userSelect: 'none', padding: '2px 5px', fontSize: '10px', marginTop: '2px',
                borderRadius: '3px', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginLeft: '10px'
              }}
                onClick={() => {
                  setOverlay(<CreateEnvVarOverlay/>, false, 'left')
                }}
              >
                <i style={{color: 'rgb(29, 191, 113)'}} className="far fa-plus-circle"></i>&nbsp;Add New
              </span>
              {
                isLoading ?
                <Spinner style={{marginLeft: '10px', borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
                :
                null
              }
              <span style={{
                position: 'absolute', top: '19px', right: '19px',
                cursor: 'pointer', userSelect: 'none', padding: '2px 5px', fontSize: '10px', marginTop: '2px',
                borderRadius: '3px', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginLeft: '10px'
              }} onClick={() => { closeWindow('lvl2-envmanager') }}>
                <i className="fas fa-chevron-circle-left"></i>&nbsp;Back to Code Editor
              </span>
            </div>
            <br/>
            <div style={{fontSize: '12px', lineHeight: 1.4, color: 'rgb(150, 150, 150)'}}>
              You can define environment variables as key-value pairs that are accessible to appointed function code. These are useful for storing configuration settings or API keys without the need to hardcode values in the function code.
            </div>
            <br/>
            <div style={{width: '100%'}}>
              <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
                <div style={{fontSize: '13px', fontStyle: 'italic', margin: '0 10px 0 0'}}>
                  <i className='far fa-search'></i>&nbsp;&nbsp;Search Environment Variables
                </div>
                <FlowInput
                  style={{fontFamily: 'Roboto Mono'}}
                  value={searchTerm}
                  placeHolder='Variable Name'
                  onChange={(e) => { setSearchTerm(String(e.target.value)) }}
                />
              </div>
            </div>
          </div>
          <div style={{width: '100%', padding: '10px', flexGrow: 1, overflowY: 'scroll'}}>
            {storageView}
          </div>
        </div>
      </div>
    </div>
  )
}

const AuthStorageCard = ({authSecret}) => {
  const {sandboxState, sandboxSetState, isEnvVarAttached, envVarListUpdate} = useContext(SandBoxContext)
  var isLocked = sandboxState?.functionDetails?.title ? true : false
  let {createdAt, name, key, description} = authSecret
  let createdAtLocal = new Date(createdAt)
  const isMounted = useIsMounted()
  const [isShowingMore, setIsShowingMore] = useState(false)
  const [isCopiedNotifier, setIsCopiedNotifier] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  let dateString = createdAtLocal.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  let isAttached = isEnvVarAttached(authSecret?._id)

  const deleteHandler = async (id) => {
    if(!isDeleteLoading) {
      try {
        setIsDeleteLoading(true)
        let response = await Axios.post('authStorage/delete', {authSecretID: id})
        let authSecret = response.data?.authSecret
        let authStorageCache = sandboxState?.authStorageCache ?? []
        authStorageCache = authStorageCache?.filter(a => a?._id !== authSecret._id)
        sandboxSetState({authStorageCache})
        setIsDeleteLoading(false)
      } catch(err) {
        setIsDeleteLoading(false)
      }
    }
  }

  return (
    <div className='sv2-authStorage-card'>
      <div className='name'>
        {name}
        <span>
          <InfoToolTip 
            style={{marginLeft: '8px', fontStyle: 'italic', fontWeight: 400}}
            icon={(<i style={{fontSize: '11px', color: 'orange'}} className="fas fa-lock"></i>)}
          >
            The environment variable's value is encrypted for security purposes. The actual value is not visible. To modify this variable, please delete the current entry and create a new one with the desired value.
          </InfoToolTip>
        </span>
      </div>
      <div className='key'>
        <span style={{fontFamily: 'Roboto Mono', color: 'rgb(29, 191, 113)'}}>{key}</span>
        <span style={{
          cursor: isLocked ? 'not-allowed' : 'pointer', userSelect: 'none', padding: '1px 5px', fontSize: '10px',
          borderRadius: '3px', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginLeft: '7.5px'
        }}
          onClick={() => {
            if(isLocked === false) {
              if(isAttached) {
                envVarListUpdate(authSecret, 'detach')
              } else {
                envVarListUpdate(authSecret, 'attach')
              }
            }
          }}
        >
          {
            isAttached ? 
            <>
              <i className='far fa-unlink' style={{marginRight: '5px'}}></i>
              Detach from current Environment
            </>
            :
            <>
              <i className='far fa-link' style={{marginRight: '5px', color: 'rgb(97, 176, 239)'}}></i>
              Attach to current Environment
            </>
          }
        </span>
        <span style={{
          cursor: 'pointer', userSelect: 'none', padding: '1px 5px', fontSize: '10px',
          borderRadius: '3px', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginLeft: '7.5px'
        }}
          onClick={() => {
            copyToClipboard(key, () => {
              if(isMounted) {
                setIsCopiedNotifier(true)
                setTimeout(() => {
                  if(isMounted) {
                    setIsCopiedNotifier(false)
                  }
                }, 1500)
              }
            })
          }}
        >
          {
            isCopiedNotifier ? 
            <i className='far fa-check' style={{marginRight: '5px'}}></i>
            :
            <i className='far fa-copy' style={{marginRight: '5px'}}></i>
          }
          Env Key
        </span>
      </div>
      { description? 
        <div className={`description${isShowingMore ? '' : ' ellipsis'}`}>
          <strong>Description:</strong> {description}
        </div>
        :
        <div className={`description${isShowingMore ? '' : ' ellipsis'}`}>
          <strong>No Description</strong>
        </div>
      }
      <div className='createdAt'>Created at {dateString}</div>
      <div className='more'>
        <span 
          style={{cursor: 'pointer'}}
          onClick={() => { setIsShowingMore(!isShowingMore) }}
        >Show {isShowingMore ? 'Less' : 'More'}</span>
      </div>
      {isShowingMore ? 
        <div className='code-block'>
          <CodeSnippet keyName={key}/>
          <div className='delete'
            onClick={() => {
              // note:action
              if(!isDeleteLoading) {
                deleteHandler(authSecret?._id)
              }
            }}
          >
            {
              isDeleteLoading ? 
              <Spinner style={{width: '10px', height: '10px', borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', borderWidth: '2px'}}/>
              :
              <><i className='far fa-trash-alt'></i></>
            }&nbsp;Permanently Delete Variable
          </div>
        </div> 
        : 
        null
      }
    </div>
  )
}

const CodeSnippet = ({keyName}) => (
  <div style={{
    margin: '5px 0 5px 0', lineHeight: 1.6, position: 'relative',
    fontFamily: 'Roboto Mono', fontSize: '14px', fontWeight: 400, width: '100%', padding: '6px 12px', borderRadius: '5px', backgroundColor: 'rgb(39, 44, 53)', color: 'rgb(157, 155, 151)'}}>
    <span style={{color: 'rgb(198, 121, 221)'}}>import</span>&nbsp;os<br/>
    value = os.<span style={{color: 'rgb(171, 178, 192)'}}>environ</span>.<span style={{color: 'rgb(97, 176, 239)'}}>get</span>(<span style={{color: 'rgb(152, 195, 121)'}}>'{keyName}'</span>)
    <i className='far fa-copy' style={{position: 'absolute', top: '12.5px', right: '12.5px', fontSize: '14px', cursor: 'pointer'}}
      onClick={() => copyToClipboard(`import os\nvalue = os.environ.get('${keyName}')`)}
    ></i>
  </div>
)

const SimpleJSONEditor = ({script='', placeHolder='', height='130px', onChange=()=>{}}) => {
  const { jsonEditorRef, sandboxState } = useContext(SandBoxContext)
  useEffect(() => {
    setTimeout(() => {
      try {
        var t = jsonEditorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 500)
    setTimeout(() => {
      try {
        var t = jsonEditorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 3000)
  }, [])
  var editable = true
  if(sandboxState?.metadata?.jsonEditorActive === false) editable = false
  return(
    <div className='simple-editor' style={{height: height, borderRadius: '5px', border: 'none'}}>
      <CodeMirror
        ref={jsonEditorRef}
        value={script}
        placeholder={placeHolder}
        className='sv2-codemirror-mini-configure'
        height='100% !important'
        editable={editable}
        autoFocus={false}
        theme={atomone}
        extensions={[json()]}
        indentWithTab={true}
        basicSetup={{
          lineNumbers: false,
          highlightActiveLineGutter: true,
          highlightSpecialChars: true,
          history: true,
          foldGutter: true,
          drawSelection: true,
          dropCursor: true,
          allowMultipleSelections: true,
          indentOnInput: true,
          syntaxHighlighting: true,
          bracketMatching: true,
          closeBrackets: true,
          autocompletion: true,
          rectangularSelection: true,
          crosshairCursor: true,
          highlightActiveLine: true,
          highlightSelectionMatches: true,
          closeBracketsKeymap: true,
          defaultKeymap: true,
          searchKeymap: true,
          historyKeymap: true,
          foldKeymap: true,
          completionKeymap: true,
          lintKeymap: true
        }}
      />
    </div>
  )
}


export {AIPanel, CodePanel, LogViewer, SimpleCodePanel, SimpleJSONEditor, AuthStorageManager}