import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles.scss'
import '../styles.scss'
import logo from '../sellagen_cropped.png'
import { AIPanel, AuthStorageManager, SimpleCodePanel } from '../ConsoleKeyComponents'
import FunctionInstructions, {FunctionDetails, FunctionExample, FunctionLists} from './instructions'
import SandBoxContext, {withSandBoxHandler} from '../../../contexts/SandBoxContext'
import FuncTesting from './function-testing'
import SandBoxChat from './sandbox-chat'
import Agent from '../title'
import { Context } from '../../../contexts/GlobalContext'
import { FlowButton, FlowOverlayPrompt } from '../../../components/flow-ui/flow-blocks'
import LLMContextPanel from './context-panel/contextPanel'
import LLMServicePanel from './service-panel/servicePanel'
import { useNavigate } from 'react-router-dom'
import Axios from '../../../utilities/axios'

const SandBox = ({demo=false}) => {
  const {sandboxState, sandboxSetState, updateMetadata, handlePrompt, loadChats, setOverlay} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  const [prompt, setPrompt] = useState('')
  const rightPanelRef = useRef(null)
  var specialMsg = sandboxState?.chatPanelSecondaryPlaceholderMsg || null

  useEffect(() => {
    if(!sandboxState?.chatID && globalState?.user?._id) {
      loadChats()
    }
  }, [globalState?.user?._id])

  useEffect(() => {
    const queryParams = new URLSearchParams(window?.location?.search || '')
    let onboardingState = queryParams.get('onboarding')
    if(onboardingState === 'success') {
      window.location = '/nelima'
    }

    if(globalState?.user?._id) {
      loadChats()
    }
    // if(localStorage.getItem('code-initiation') === 'true') {
    //   try {
    //     localStorage.removeItem('code-initiation')
    //     temporaryStateManagement('load')    // removes state after loading
    //   } catch(err) {
    //     console.log(err)
    //   }
    // }

    if(!demo) {
      document.documentElement.style.backgroundColor = 'rgb(var(--theme-nelima-dark-val))'
    }

    //--theme-nelima-dark-val

    const handleMediaQueryChange = () => {
      let position = window.innerWidth >= 950 ? 'relative' : 'absolute'
      try {
        position = window.getComputedStyle(rightPanelRef.current).getPropertyValue('position')
        if(position === 'relative') {
          sandboxSetState({showSidePanel: true})
        } else if(position === 'absolute') {
          sandboxSetState({showSidePanel: false})
        }
      } catch(err) {
        console.log('Style property error')
      }
    }
    const mediaQueryList = window.matchMedia('(max-width: 950px)')

    mediaQueryList.addEventListener('change', handleMediaQueryChange)
    if (mediaQueryList.matches) {
      handleMediaQueryChange()
    }
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  var isPanelTestMode = false
  // var initialPrompt = ''
  if(sandboxState?.metadata?.promptTesting) {
    isPanelTestMode = sandboxState?.metadata?.promptTesting?.enabled === true
    // initialPrompt = sandboxState?.metadata?.promptTesting?.prompt || ''
  }
  var currWindow = <SandBoxIntro demo={demo}/>
  if(sandboxState?.panelMode === 'integrate') {
    if(sandboxState?.windowStack?.includes('lvl1-details')) {
      currWindow = <FunctionDetails updatePrompt={setPrompt}/>
    } else if(sandboxState?.windowStack?.includes('lvl1-instructions')) {
      currWindow = <FunctionInstructions/>
    } else if(sandboxState?.windowStack?.includes('lvl1-functesting')) {
      currWindow = <FuncTesting/>
    } else if(sandboxState?.windowStack?.includes('lvl1-funclist')) {
      currWindow = <FunctionLists/>
    } else if(sandboxState?.windowStack?.includes('lvl1-funcexample')) {
      currWindow = <FunctionExample updatePrompt={setPrompt}/>
    }
  } else if(sandboxState?.panelMode === 'context') {
    currWindow = <LLMContextPanel/>
  } else if(sandboxState?.panelMode === 'services') {
    currWindow = <LLMServicePanel/>
  }

  let leftPanel = null
  if(sandboxState.panelMode === 'integrate') {
    if(sandboxState?.windowStack?.includes('lvl2-envmanager')) {
      leftPanel = <AuthStorageManager/>
    } else {
      leftPanel = <SimpleCodePanel/>
    }
  } else {
    leftPanel = <SandBoxChat/>
  }

  return (
    <div className={'sndbx-root sndbx-full'}>
      <div className='sndbx-container'>
        {
          sandboxState?.overlayFull ?
          <div className='sndbx-full-overlay'>
            {sandboxState?.overlayFull}
          </div>
          :
          null
        }
        <div className='sv2-spdrn-sidemenu'></div>
        <div className='sv2-spdrn-body'>
          <div className='sndbx-right-panel-toggle-reverse'
            onClick={() => { sandboxSetState({showSidePanel: true}) }}
          >
            <i className="far fa-angle-left"></i>
          </div>
          <div className='sv2-spdrn-main-view'>
            <div className='sv2-spdrn-left-panel' style={{backgroundColor: 'rgb(26, 29, 34)', position: 'relative'}}>
              {
                sandboxState?.overlayLeft ?
                <>{sandboxState?.overlayLeft}</>
                :
                null
              }
              <div className='sndbx-right-panel-toggle'
                style={sandboxState?.showSidePanel === true ? {display: 'block'} : sandboxState?.showSidePanel === false ? {display: 'none'} : {}}
                onClick={() => { sandboxSetState({showSidePanel: false}) }}
              >
                <i className="far fa-angle-right"></i>
              </div>
              {/* <div className='sv2-spdrn-code-panel' style={{overflow: 'hidden', maxWidth: '850px', margin: 'auto'}}> */}
              <div className='sv2-spdrn-code-panel' style={{overflow: 'hidden', maxWidth: '100%', margin: 'auto'}}>
                <AIPanel
                  isLoading={sandboxState?.isPromptLoading}
                  onSubmit={() => { handlePrompt(prompt, () => { setPrompt('') }) }}
                  placeHolder= {specialMsg || 'Chat with Nelima'}
                  onChange={ e => { setPrompt(e.target.value) }}
                  value={prompt}
                  proMode={true}
                  testMode={isPanelTestMode}
                  onTestToggle={() => {
                    updateMetadata('delete', 'promptTesting', null)
                    sandboxSetState({testPrompts: []})
                  }}
                />
                {leftPanel}
              </div>
            </div>
            <div className='sv2-spdrn-right-panel' ref={rightPanelRef} style={sandboxState?.showSidePanel === true ? {display: 'flex'} : sandboxState?.showSidePanel === false ? {display: 'none'} : {}}>
              {currWindow}
              {
                sandboxState?.overlay ?
                <>
                  {sandboxState?.overlay}
                </>
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TempSetting = ({menuToggle=() => {}}) => {
  const navigate = useNavigate()
  const {setOverlay, sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {syncUser} = useContext(Context)
  useEffect(() => {
    const handleClickOutside = (event) => {
      const introProfileDiv = document.querySelector('.intro-profile')

      if (introProfileDiv && !introProfileDiv.contains(event.target)) {
        menuToggle(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const chatContextRemoveHandler = async () => {
    // todo: Needs to be reconfigured. Right now, all documents are deleted
    // todo: In future only chat specific context documents should be deleted
    try {
      Axios.get('/documents/remove-all').then(() => {}).catch(() => {})
      Axios.post('/function/delete-chat', {chatID: sandboxState?.chatID || null}).then(() => {}).catch(() => {})
      Axios.get('/scheduler/cleanup').then(() => {}).catch(() => {})
    } catch(err) {
      // do nothing
    }
  }

  return(
    <div className='intro-profile-temp-menu'>
      <div className='intro-temp-menu-option'
        onClick={() => { 
          menuToggle(false)
          window.open('https://discord.gg/zJNDvc33', '_blank')
        }}
      >
        <i className="far fa-signal-stream"></i>
        <span>Join Discord</span>
      </div>
      {
        sandboxState?.prompts?.length > 0 ?
        <div className='intro-temp-menu-option' style={{color: 'crimson'}}
          onClick={() => {
            setOverlay(
              <FlowOverlayPrompt
                title='Delete Chat History'
                promptMsg={
                  <>
                    Would you like to clear your current chat history? Please note that once cleared, it cannot be restored.
                  </>
                }
                titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
                proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Proceed</>}
                cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
                onProceed={() => {
                  chatContextRemoveHandler()
                  localStorage.removeItem('prompts')
                  sandboxSetState({prompts: [], chatID: null})
                  menuToggle(false)
                  setOverlay(null)
                  syncUser()
                }}
                onCancel={() => {
                  setOverlay(null)
                }}
              />
            )
          }}
        >
          <i className="far fa-trash-alt"></i>
          <span>Delete Chat</span>
        </div>
        :
        null
      }
      <div className='intro-temp-menu-option'
        onClick={() => {
          navigate('/processing', {state: { mode: 'logout' }})
        }}
      >
        <i className="far fa-sign-out"></i>
        <span>Sign Out</span>
      </div>
    </div>
  )
}

const SandBoxIntro = ({demo = false}) => {
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  const [showTempSetting, setShowTempSetting] = useState(false)
  const navigate = useNavigate()
  const isOnline = sandboxState?.isOnline ?? false
  var signInOption = null
  if(!demo && !globalState?.user?._id) {
    signInOption = (
      <div style={{marginTop: '25px', display: `${demo ? 'none' : 'block'}`}}>
        <div 
          style={{cursor: 'pointer', fontSize: '24px', fontWeight: 200, display: 'flex', alignItems: 'center'}}
          onClick={() => {
            navigate('/login')
          }}
        >Sign In&nbsp;<i className="fas fa-arrow-circle-right" style={{fontSize: '14px'}}></i></div>
        <div style={{fontSize: '11px', marginTop: '-4px'}}>
          Signing in allows you to utilize Nelima for advanced use cases
        </div>
      </div>
    )
  }
  return (
    <div className='sndbx-right-intro'>
      {globalState?.user?.firstName ?
        <div className='intro-profile'>
          <i className={`fas fa-circle sndbx-online-mark ${isOnline ? 'green' : ''}`}></i>
          <FlowButton style={{padding:'0px 5px', fontWeight: 700, fontSize: '12px', marginLeft: '5px', marginRight: '10px', filter: 'brightness(120%)', userSelect: 'none'}}
            onClick={() => { setShowTempSetting(!showTempSetting) }}
          >
            {globalState?.user?.userName || globalState?.user?.firstName}
          </FlowButton>
          {
            showTempSetting ? <TempSetting menuToggle={ val => setShowTempSetting(val) }/> : null
          }
        </div>
        :
        null
      }
      <div style={{padding: '20px'}}>
        <div style={{marginBottom: '20px', fontSize: '24px', fontWeight: 700, color: 'rgb(29, 191, 113)'}}>
          <img className='sv2-sndbx-logo' 
            src={logo}
            style={{cursor: 'pointer'}}
            onClick={() => window.location.href = '/'}
          /><br/>
          Nelima
        </div>
        <div style={{display: `${demo ? 'block' : 'none'}`,  marginBottom: '10px', fontSize: '16px', fontWeight: 500, cursor: 'pointer'}}
          onClick={() => {
            window.location.href = '/nelima'
          }}
        >Get Started&nbsp;&nbsp;<i className="fas fa-arrow-circle-right" style={{fontSize: '14px'}}></i></div>
        <ul style={{marginBottom: '20px'}}>
          <li style={{listStyleType: 'disc'}}>
            <Agent style={{color: 'rgb(29, 191, 113)'}}/> is designed for taking actions on your behalf with natural language commands.
          </li>
          <li style={{listStyleType: 'disc'}}>
            As the first-ever action-focused AI, <Agent style={{color: 'rgb(29, 191, 113)'}} add="'s"/> capabilities are powered by the AI enthusiast community.
          </li>
          <li style={{listStyleType: 'disc'}}>
            That means you can contribute to <Agent style={{color: 'rgb(29, 191, 113)'}} add="'s"/> ever expanding capability.&nbsp;
            <span style={{color: 'rgb(29, 191, 113)', cursor: 'pointer'}}
              onClick={() => { sandboxSetState({panelMode: 'integrate'}) }}
            >Integrate an action now.</span>
          </li>
        </ul>
        <div style={{fontWeight: 500}}>
          Join the discussion on <span 
            style={{
              color: 'rgb(87, 97, 236)',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => { window.open('https://discord.gg/zGdUve2Nhk', '_blank') }}
          >Discord</span> to see how others are contributing.
        </div>
        {signInOption}
      </div>
      <div className='sndbx-panel-close' style={{position: 'absolute', left: '15px', bottom: '15px'}}
        onClick={() => {
          sandboxSetState({showSidePanel: false})
        }}
      >
        <i className="far fa-chevron-right"></i>
      </div>
    </div>
  )
}

export default withSandBoxHandler(SandBox)